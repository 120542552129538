import { TBankReviewRequest } from '@app/constants/ApiTypes/requests'

import * as api from '@app/utils/api'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'
import { createThunk } from '@app/store/thunk'

import { getTBankReviewsByQueryDescriptor, getTBankReviewsSummaryDescriptor } from './tbankReviews.descriptors'

export const getTbankReviewsByQuery = new ApiActionBuilder(getTBankReviewsByQueryDescriptor)
  .setInit((request: TBankReviewRequest) => ({
    method: 'GET',
    endpoint: api.path('/api/v2/t_bank_reviews', request),
    headers: api.headers(),
  }))
  .build()

export const getTbankReviewsNext = (request: TBankReviewRequest) =>
  createThunk(async (dispatch, getState) => {
    if (getState().tbank_reviews.meta.loading) return
    const nextPage = (getState().tbank_reviews.meta.current_page ?? 0) + 1
    return dispatch(getTbankReviewsByQuery({ ...request, page: nextPage }))
  })

export const getTbankReviewsSummary = new ApiActionBuilder(getTBankReviewsSummaryDescriptor)
  .setInit(() => ({
    method: 'GET',
    endpoint: api.path('/api/v2/t_bank_reviews/summary'),
    headers: api.headers(),
  }))
  .build()
