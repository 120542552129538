import { RequestReviewCreateRequest, RequestReviewQACreateRequest } from '@app/constants/ApiTypes/requests'

import * as api from '@app/utils/api'
import { omit } from '@app/utils/omit'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'
import { createThunk } from '@app/store/thunk'

import { getPublicParentReviewsDescriptor, getReviewsDescriptor, postRequestReviewsDescriptor, postRequestReviewsQADescriptor } from './reviews.descriptors'

export const getPublicParentReviews = new ApiActionBuilder(getPublicParentReviewsDescriptor)
  .setInit((page: number, per_page: number = 10) => {
    const query = {
      'filter[length_gteq]': 50,
      'filter[length_lteq]': 250,
      page,
      per_page,
    }
    return {
      method: 'GET',
      endpoint: api.path('/api/v2/reviews/public/from_parents', query),
      headers: api.headers(),
      meta: { query },
    }
  })
  .build()

export const getInitialPublicParentReviews = () =>
  createThunk((dispatch, getState) => {
    return dispatch(getPublicParentReviews(1, getState().public_parent_reviews_meta.per_page))
  })

export const getNextPublicParentReviews = () =>
  createThunk((dispatch, getState) => {
    const meta = getState().public_parent_reviews_meta
    if (meta.fetching || meta.fetched_all) return
    return dispatch(getPublicParentReviews(meta.page + 1, meta.per_page))
  })

export const getReviews = new ApiActionBuilder(getReviewsDescriptor)
  .setInit((user_token: string, page: number, per_page = 10) => {
    const urlquery = { page, per_page, token: user_token }
    return {
      method: 'GET',
      endpoint: api.path('/api/v2/reviews', urlquery),
      headers: api.headers(),
      bailout: ({ reviews }) => (page === 1 ? reviews.query && reviews.query.user_token === user_token : reviews.meta.loading),
      meta: { query: { ...omit(urlquery, 'token'), user_token } },
    }
  })
  .build()

export function fetchReviews(user_token: string, per_page = 10) {
  return getReviews(user_token, 1, per_page)
}

export function fetchNextReviews() {
  return createThunk((dispatch, getState) => {
    const { meta: storeMeta, query } = getState().reviews

    return dispatch(getReviews(query.user_token, (storeMeta.current_page || 0) + 1, storeMeta.per_page || 10))
  })
}

export const postRequestReviews = new ApiActionBuilder(postRequestReviewsDescriptor)
  .setInit((request: RequestReviewCreateRequest, reviewer: 'sitter' | 'parent') => ({
    method: 'POST',
    endpoint: api.path('/api/v2/reviews'),
    headers: api.headers(),
    body: JSON.stringify(request),
    meta: { request_id: request.request_id, reviewer },
  }))
  .build()

export const postRequestReviewsQA = new ApiActionBuilder(postRequestReviewsQADescriptor)
  .setInit((request: RequestReviewQACreateRequest) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/reviews/qa'),
    headers: api.headers(),
    body: JSON.stringify(request),
  }))
  .build()
